/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { navigate, withPrefix } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
}

const types = {
  default: 'button',
  button: 'button',
  submit: 'submit'
}

const Button = ({
  children,
  className = '',
  size,
  type,
  navigateTo,
  disabled = false,
  loadingSpinner
}) => {
  const [disabledButton, setDisabledButton] = useState(disabled)
  const [loadingState, setLoadingState] = useState(false)

  useEffect(() => {
    setDisabledButton(disabled)
    setLoadingState(false)
  }, [disabled])

  const handleClick = navigateTo => {
    if (navigateTo) {
      setDisabledButton(true)
      setLoadingState(true)
      navigate(withPrefix(navigateTo))
    }
  }

  return (
    <button
      type={types[type] || types.default}
      className={`
        ${sizes[size] || sizes.default}
        ${disabledButton && 'opacity-50 cursor-not-allowed'}
        ${className}
        bg-secondary
        hover:opacity-75
        rounded-full
        text-primaryblack
        font-medium
    `}
      onClick={() => handleClick(navigateTo)}
      disabled={disabledButton}
    >
      {children}
      {loadingSpinner && loadingState && (
        <FontAwesomeIcon className="ml-2 animation-spin" icon={faSyncAlt} />
      )}
    </button>
  )
}

export default Button
