import React from 'react'
import { Link } from 'gatsby'

import LogoIcon from '../../../../../svg/LogoIcon'
import Button from '../../Button'

const Header = () => {
  return (
    <header className="sticky top-0 bg-white shadow z-10">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-4">
        <Link to="/" className="text-primary font-medium">
          <div className="flex items-center text-2xl">
            <div className="w-12 mr-3">
              <LogoIcon />
            </div>
            F<span style={{ color: '#fdca40' }}>&gt;</span>sterWeb
          </div>
        </Link>
        <div className="flex mt-4 sm:mt-0">
          <Link className="px-4" to="/" activeClassName="text-primary">
            Home
          </Link>
          <Link className="px-4" to="/learn-more" activeClassName="text-primary">
            Learn more
          </Link>
          {/* <Link className="px-4" to="/blog/" activeClassName="text-primary" partiallyActive>
            Blog
          </Link> */}
          <Link className="px-4" to="/rankings/" activeClassName="text-primary" partiallyActive>
            Web Rankings
          </Link>
        </div>
        <div className="hidden md:block">
          <Button className="text-sm" navigateTo="/contact">
            Request an Invite
          </Button>
        </div>
      </div>
    </header>
  )
}

export default Header
