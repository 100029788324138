import React from 'react'
import Footer from './components/Footer'
import Header from './components/Header'

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main className="text-gray-900">{children}</main>
    </div>
  )
}

export default Layout
